import Quill from 'quill'
import { querySelectorAll } from '../utils/polyfills'

export default class Form {
  constructor(options={}) {
    this.toolbar = options.toolbar || [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link'],
    ]
    options.components.forEach(c => this[c]())
  }

  textEditor() {
    querySelectorAll('.j-text-editor').forEach((el) => {
      const quillInstance = new Quill(`#${el.id}`, {
        modules: { toolbar: this.toolbar },
        placeholder: '',
        theme: 'snow'
      });
      const hiddenInput = el.nextElementSibling
      const editor = el.querySelector('.ql-editor')
      if (editor && hiddenInput) editor.innerHTML = hiddenInput.value
      quillInstance.on('text-change', () => {
        if (editor && hiddenInput)
          hiddenInput.value = this.getContent(editor.innerHTML, quillInstance)
      })
    })
  }

  getContent(content, editorInstance) {
    let _content = content
    if ( !editorInstance.getText().trim().length ) {
      _content = ''
    }
    return _content
  }
}
