import Dropdown from "bootstrap/js/src/dropdown";

export const initDropdowns = (domScope = document) => {
  const dropdownTriggerList = [].slice.call(domScope.querySelectorAll('[data-toggle="dropdown"]'));
  const options = {
    delay: 200,
    boundary: "window"
  };
  dropdownTriggerList.forEach((dropdownTriggerEl) => new Dropdown(dropdownTriggerEl, options));
};

document.addEventListener("DOMContentLoaded", () => {
  initDropdowns();
});
