import { querySelectorAll } from "./utils/polyfills";

export default class {
  constructor(container, nodeNames = ["p"], _buttonElement) {
    this.nodeNames = nodeNames;
    this.container = container;
    this.registerEvents();
  }

  registerEvents() {
    const self = this;
    querySelectorAll(".j-font-size-toggle").forEach((toggle) => {
      toggle.addEventListener("click", (event) => {
        event.preventDefault();
        self.resize(toggle.dataset.direction);
      });
    });
  }

  resize(direction = 1) {
    const baseSize = 100;
    const adjustment = baseSize + 15 * direction;
    this.nodeNames.forEach((nodeName) => {
      const nodes = this.container.querySelectorAll(nodeName);
      nodes.forEach((n) => n.setAttribute("style", `font-size: ${adjustment}%`));
    });
  }
}
