import FontSizeToggle from "../../font_size_toggle";

document.addEventListener("DOMContentLoaded", () => {
  const footerToggle = document.querySelector(".j-footer-toggle");
  if (!footerToggle) return;

  const toggleDeFooter = (event) => {
    event.preventDefault();
    let actionMethod = "add";
    const openClassName = "open";
    const mainFooter = document.querySelector(".c-footer");
    const footer = document.getElementById("js-footer--de");
    if (footer.classList.contains(openClassName)) {
      actionMethod = "remove";
    }
    const updateMainHeader = () => {
      mainFooter && mainFooter.classList[actionMethod](openClassName);
    };

    if (actionMethod === "remove") {
      setTimeout(updateMainHeader, 300);
    } else {
      updateMainHeader();
    }
    footer.classList[actionMethod](openClassName);
    footerToggle.classList[actionMethod](openClassName);
    footerToggle.blur();
  };

  const deFooterLinks = [...document.querySelectorAll(".c-footer--delaware a")];
  if (deFooterLinks.length) {
    const firstDeFooterLink = deFooterLinks.first();
    const lastDeFooterLink = deFooterLinks.last();
    firstDeFooterLink && firstDeFooterLink.addEventListener("focus", toggleDeFooter);
    lastDeFooterLink && lastDeFooterLink.addEventListener("blur", toggleDeFooter);
  }

  footerToggle.addEventListener("click", toggleDeFooter);

  new FontSizeToggle(document.getElementById("js-footer--de"));
});
