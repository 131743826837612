// copied from simple_line chart 957e46
import Chart from "./chart";

import { bar } from "billboard.js";
import { colors } from "./options";
import compact from "lodash/compact";
import { roundToMultipleOf } from "../utils/number";
import { querySelectorAll } from "../utils/polyfills";
import { deepCopy } from "../utils/index";
import moment from "moment";
import * as __min from "lodash/min";
import * as __max from "lodash/max";

export default class SimpleBar extends Chart {
  constructor(props, containerEls, options = {}) {
    let _options = Object.assign(
      {
        names: null,
        padding: {
          right: 20,
          left: 75,
          top: 0,
          bottom: 20
        },
        types: {
          data1: bar()
        },
        axes: {
          data1: "y"
        },
        showLegend: true,
        showLoader: true,
        unit: "percent",
        yAxis: {
          label: "",
          min: null,
          max: null,
          tick: {}
        },
        xAxis: {
          tick: {
            rotate: 0,
            culling: false,
            fit: false
          }
        },
        y2Axis: {},
        axisIsCategory: false,
        colorPerCategory: false,
        labelRotation: 90,
        colors: colors(),
        orientation: "v",
        data: {}
      },
      options
    );
    super(props, containerEls, _options);
    this.updateXAxisLabels();
    this.xAxisLabelsRotated =
      this.options.rotateAxisLabels || this.hidePoints() || this.getPointCount() > 10;
    this.names = props.names;
    this.chart = this._init();
    return this.chart;
  }

  updateXAxisLabels() {
    const { dateFormat, axisIsCategory } = this.options;
    this.categories = this.props.dates || this.props.categories;
    const format = dateFormat || this.props.dateFormat || "YYYY";
    if (!axisIsCategory) {
      this.categories = this.categories.map((cat) => {
        const date = moment(cat, "M/D/YY");
        if (date.isValid() && format) {
          return date.format(format);
        }
        // Return string if not a valid date
        return cat;
      });
    }
  }

  _prepareColumns() {
    return [...this.props.data];
  }

  getMinOfSets() {
    return __min(
      deepCopy(this.props.data).map((t) => {
        t.shift();
        return __min(t);
      })
    );
  }

  getMaxOfSets() {
    return __max(
      deepCopy(this.props.data).map((t) => {
        t.shift();
        return __max(t);
      })
    );
  }

  _getLegendLabelData() {
    return {};
  }

  _generate() {
    this.updateXAxisLabels();
    const chart = super._generate();
    this._addAdditionalUi();
    this._updateLoadingState(false);
    return chart;
  }

  _onRendered(chart) {
    super._onRendered(chart, true);
    // Move axis label if axis labels are rotated
    if (this.xAxisLabelsRotated) {
      querySelectorAll(".bb-axis-x-label").forEach((l) => l.setAttribute("dy", "3.5em"));
    }
    return;
  }

  _buildOptions() {
    // Build basic options for line chart
    // Copy data columns array for labels and points
    const self = this;
    const columns = this._prepareColumns();
    const {
      size,
      padding,
      xAxis,
      yAxis,
      labelRotation,
      types,
      axes,
      colors,
      colorPerCategory,
      orientation,
      data
    } = this.options;

    let { min, max } = yAxis || {};
    // If no min or max values are given in options obj,
    // determine those values by evaluating all the data sets
    if (min === null || min === undefined) {
      min = this.getMinOfSets();
    }
    if (max === null || max === undefined) {
      max = this.getMaxOfSets();
    }
    // Round the min/max values for a cleaner axis
    min = roundToMultipleOf(min, 5, "floor");
    max = roundToMultipleOf(max, 5);

    this._options = Object.assign(this.options, {
      size,
      legend: { show: false },
      padding,
      data: {
        columns,
        color: (c, d) => {
          if (colorPerCategory && typeof d === "object") {
            return colors[d.index];
          }
          return c;
        },
        type: "bar",
        types,
        axes,
        ...data
      },
      axis: {
        rotated: orientation === "h",
        x: {
          type: "category",
          categories: this.props.categories,
          label: {
            text: xAxis.label || null,
            position: "outer-center"
          },
          tick: Object.assign(
            {
              rotate: this.xAxisLabelsRotated ? labelRotation : null,
              multiline: false
            },
            xAxis.tick
          )
        },
        y: {
          label: {
            text: yAxis.label,
            position: "outer-middle"
          },
          tick: {
            // values: ticks(min, max, 10),
            multiline: false
          }
        }
      },
      grid: {
        x: { show: false },
        y: { show: true }
      },
      bindto: this.containerEl,
      tooltip: { contents: this._tooltip.bind(this) },
      onrendered() {
        self._onRendered(this);
      }
    });
  }

  redraw(data, options = {}, props) {
    this.chart.destroy();
    this.props = props;
    this.options = Object.assign(this.options, options);
    this.categories = this.props.dates || this.props.categories;
    this.updateXAxisLabels();
    this.chart = this._init();
  }

  _tooltip(data) {
    const values = compact(data).map((d, i) => {
      if (d.value == null) return null;
      const categoryName = this.categories[d.x];
      let value = `<span class='c-tooltip-chart__value c-tooltip-chart__value--${this.options.colors[i]} d-inline'>${d.value}</span>`;
      return `
          <div class='c-tooltip-chart__value-wrapper'>
            <span class='c-tooltip-chart__date'>
              ${this.getSetName(d.name, i)}
            </span>
            <h4 class='c-tooltip-chart__title'>${categoryName}</h4>
            <span class='c-tooltip-chart__value-container'>
              ${value}
            </span>
          </div>
        `;
    });
    return `
      <div class='c-tooltip-chart'>
        ${values.join("")}
      </div>
    `;
  }

  _onResize(chart) {
    super._onResize(chart);
    return;
  }

  _removeClipPaths() {
    // noop
  }

  getSetName(setName, index) {
    let name = typeof setName === "string" ? this._formatLabel(setName) : setName;
    const { set_names, names } = this.props;
    if (set_names || names) {
      name = (set_names || names)[index];
    }
    return name;
  }

  getPointCount() {
    const { data } = this.props;
    if (data.length) {
      return this.props.data.first().length;
    }

    return 0;
  }

  hidePoints() {
    const { granularity } = this.props;
    return this.getPointCount() > 100 || (granularity && granularity === "daily");
  }

  _addLegend() {
    // noop
    return;
  }
}
