Array.prototype.last = function () {
  return this[this.length - 1];
};

Array.prototype.first = function () {
  return this[0];
};

Array.prototype.includes = function (match) {
  return this.indexOf(match) !== -1;
};
