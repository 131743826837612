import { sendGaUserInteractionEvent, GaEventCategories } from "javascript/utils/google_analytics";

document.addEventListener("DOMContentLoaded", () => {
  const toggleMobileNav = (): void => {
    const { body } = document;
    body.classList.toggle("with-mobile-nav");
    (document.querySelector(".l-main__nav") as HTMLElement).classList.toggle("on-canvas");
  };
  const mobileNavToggle = document.querySelectorAll(".j-mobile-nav-toggle");
  const navLink = document.querySelectorAll(".j-nav-link");
  mobileNavToggle.forEach((el) => el.addEventListener("click", toggleMobileNav));
  navLink.forEach((el) => el.addEventListener("click", toggleMobileNav));

  const parentNavItems = document.querySelectorAll(".j-toggle-nav-children");
  parentNavItems.forEach((el) => {
    el.addEventListener("click", function (this: HTMLElement, event: Event): void {
      event.preventDefault();
      const parent = this.parentElement as HTMLElement;
      [parent, parent.nextElementSibling].forEach((elem) => {
        if (!elem) return;
        (elem as HTMLElement).style.maxHeight = elem.classList.contains("active")
          ? ""
          : `${String(elem.scrollHeight)}px`;
        elem.classList.toggle("active");
      });
      this.blur();
    });
  });

  const desktopNavToggle = document.querySelectorAll(".j-desktop-nav-toggle");
  const toggleSideBar = (): void => {
    const leftNav = document.getElementById("leftnav");
    const navParent = leftNav && leftNav.parentElement;
    navParent && navParent.classList.toggle("closed");
    document.getElementById("main-content")?.classList.toggle("full-bleed");
    document.getElementById("context-header")?.classList.toggle("nav-closed");
  };
  desktopNavToggle.forEach((el) => el.addEventListener("click", toggleSideBar));

  const toggle = (element: HTMLElement, blur = true) => {
    const level = Number(element.dataset.level || "1");
    let collapsible = element.nextElementSibling as HTMLElement;
    if (level > 1) {
      collapsible = element.parentElement?.nextElementSibling as HTMLElement;
    }

    [element, collapsible].forEach((elem) => {
      elem && elem.classList.toggle("open");
    });

    blur && element.blur();

    sendGaUserInteractionEvent({
      category: GaEventCategories.SideNav,
      action: `Toggle ${element.classList.contains("open") ? "Open" : "Closed"}`,
      label: element.dataset.title || ""
    });
  };

  // New side nav items collapse state
  document.querySelectorAll(".j-toggle-side-nav-children").forEach((el) => {
    el.addEventListener("click", function (this: HTMLElement): void {
      toggle(this);
    });
    el.addEventListener("keyup", function (this: HTMLElement, event): void {
      if ((event as KeyboardEvent).code === "Enter") {
        toggle(this, false);
      }
    });
  });

  // Prevent toggling nav state when clicking on a link
  document
    .querySelectorAll("a.c-side-nav__item-text_link")
    .forEach((el) => el.addEventListener("click", (e) => e.stopPropagation()));
});
