import "./nav";
import "./footer";
import "./tooltips";
import "./notes";
import "./dropdown";

// Special scroll behavior for nav links and anchored containers
import { initScrollLinks } from "../../link";
import { checkBrowserCompatibility } from "../../utils/browser";

document.addEventListener("DOMContentLoaded", () => {
  checkBrowserCompatibility();
  initScrollLinks();
  // scrollAnchorIntoView()
});
