String.prototype.capitalize = function(exclusions=[]) {
  let _exclusions = [ 'and', 'to', 'from', 'a', 'an', 'the', 'μg/dl', 'for' ]
  _exclusions.concat(exclusions)
  const _words =
    this
      .split(' ')
      .map((word) => {
        if (!_exclusions.includes(word.toLowerCase()))
          return word.charAt(0).toUpperCase() + word.slice(1)
        else
          return word
      })
  return _words.join(' ')
}

String.prototype.removeHtmlTags = function() {
  return this.replace(/<(?:.|\n)*?>/gm, '')
}

String.prototype.toHuman = function() {
  // https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-sentence-case-text
  const text = this.replace( /([A-Z])/g, " $1" ).replace('-', ' ')
  return text.charAt(0).toUpperCase() + text.slice(1)
}
