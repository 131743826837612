export const initNotes = (domScope = document) => {
  const elements = [].slice.call(domScope.querySelectorAll(".j-close-note"));
  elements.forEach((el) => {
    el.addEventListener("click", function (event) {
      event.preventDefault();
      const component = this.closest(".c-note");
      if (component) component.remove();
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  initNotes();
});
